export const eventDictionary = [
  {
    eventId:
      "0xbeabacc8ffedac16e9a60acdb2ca743d80c2ebb44977a93fa8e483c74d2b35a8",
    name: "transfer",
    topics: ["from", "to", "token"],
  },
  {
    eventId:
      "0x5c52a5f2b86fd16be577188b5a83ef1165faddc00b137b10285f16162e17792a",
    name: "approval",
    topics: ["owner", "approved", "token"],
  },
  {
    eventId:
      "0x642794df1bc2a1f4cd7b38a2493233dde0490fb4a180cda6dc439f6889e100b5",
    name: "approvalForAll",
    topics: ["owner", "operator", "approved"],
  },
  {
    eventId:
      "0x545e7c614e06aed049a20d161d73e6b571ab22ff052a9f76f57beaa0a47b4ced",
    name: "deploy",
    topics: ["owner", "nft"],
  },
  {
    eventId:
      "0x27049d4f3e41cb479253aef039dd05accc513232f111592a1f18ba130520ea3b",
    name: "bindAsset",
    topics: ["chainId", "toAsset"],
  },
  {
    eventId:
      "0x0190538bc16ee5ec3d8ea15a804848ed22ce16c07046e6268a2f2848b5ce0fc7",
    name: "bindProxy",
    topics: ["chainId", "proxy"],
  },
  {
    eventId:
      "0xf2fde38b092330466c661fc723d5289b90272a3e580e3187d1d7ef788506c557",
    name: "transferOwnershipEvent",
    topics: ["newOwner"],
  },
  {
    eventId:
      "0xbdff8ac34e7e1a32de25b46525b6f492f60051779ecfaa45cfef504d1c955bb5",
    name: "lock",
    topics: [
      "fromAssetHash",
      "fromAddress",
      "toChainId",
      "toAssetHash",
      "toAddress",
      "amount",
    ],
  },
  {
    eventId:
      "0x59508f8f44f003db0d8963c497830c110095a97c00ed9ed15d62b810bca78967",
    name: "unlock",
    topics: ["toAssetHash", "toAddress", "amount"],
  },
  {
    eventId:
      "0x47e1ab1cb7b17efe2e0c6e2bb751c654f66f45470509cca5531bd9f5d9b560c9",
    name: "addValidator",
    topics: ["validator", "stakeAccount", "revoke"],
  },
  {
    eventId:
      "0x0bbd5d377b7faad0055249c4601704e83593ea32cf44ac6ec6a2a7a006b34e1d",
    name: "stake",
    topics: ["owner", "validator", "stakeAccount", "revoke", "value"],
  },
  {
    eventId:
      "0x7ab7140dde1441863f527f703e16a1e65294608aa06494e09e0e37ae034e8979",
    name: "propose",
    topics: ["proposer", "proposalId", "proposerType", "endBlock", "value"],
  },
  {
    eventId:
      "0x1d318f1b09d515e867d20eefb8e69f20827ef94996b80c6972429efd07273b7c",
    name: "vote",
    topics: ["owner", "proposalId", "amount", "passed"],
  },
  {
    eventId:
      "0x29632d4403915dacc26d2d6f6ee6fc8fda5adebcd2d0a29b66ff212a87db3f49",
    name: "delegateRewardFactor",
    topics: ["validator", "factor"],
  },
  {
    eventId:
      "0xa9fb763ca024083f3b32979bc27ec1ff7cb648a232ed0b591cb9d3a7bb1374ad",
    name: "reward",
    topics: ["amount"],
  },
  {
    eventId:
      "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef",
    name: "transfer",
    topics: ["from", "to", "token_id"],
  },
];
